import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselItem from "../../components/CarouselItem";
import "../../App.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { debounceTime, Subject, Subscription } from "rxjs";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function ReportSlide({
	slideData,
	modalData,
	isQuestionAvail,
	isBike,
	isTruck,
	isGaragePlug,
}) {
	const [modalMoreIsOpen, setModalMoreIsOpen] = useState(false);
	const [modalViewImgIsOpen, setModalViewImgIsOpen] = useState(false);
	const [currentAccrodian, setcurrentAccrodian] = useState(0);
	const [currentSlideIndex, setcurrentSlideIndex] = useState(0);
	const [selectedSlide, setselectedSlide] = useState("");
	const carousel = useRef();
	const [initSlideIndex, setinitSlideIndex] = useState(0);
	const magnifyingGlassRef = useRef(null);
	const [[x, y], setXY] = useState([0, 0]);
	const [[imgx, imgy], setimgXY] = useState([0, 0]);
	const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
	const [showMagnifier, setShowMagnifier] = useState(false);
	var src,
		width,
		height,
		magnifierHeight = 150,
		magnifieWidth = 150;
  
	const [zoomLevel,setZoomLevel] = useState(1.5);

	useEffect(() => {
		const body = document.querySelector("body");
		body.style.overflow =
			modalMoreIsOpen || modalViewImgIsOpen ? "hidden" : "auto";
	}, [modalMoreIsOpen, modalViewImgIsOpen]);

	useEffect(() => {
		if (slideData && slideData.areaName.length)
			setcurrentAccrodian(slideData.areaName[0]);
	}, [slideData]);

	// Slider responsive options
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4,
			paritialVisibilityGutter: 60,
			slidesToSlide: 1, // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 3,
			slidesToSlide: 1, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
	};

	const CustomRightArrow = ({ onClick, ...rest }) => {
		const {
			onMove,
			carouselState: { currentSlide, deviceType, itemWidth },
		} = rest;

		const rightClickEvent = new Subject();

		useEffect(() => {
			let rightClickSubs = new Subscription();
			rightClickSubs = rightClickEvent
				.pipe(debounceTime(1000))
				.subscribe(() => onRightClick());

			return () => {
				rightClickSubs.unsubscribe();
			};
		}, [rightClickEvent]);

    function onRightClick() {
      let index = currentSlideIndex;
      if (index < slideData.images.length - 1) {
        setcurrentSlideIndex((prevIndex) => prevIndex + 1);
        index = index + 1;
      } else {
        setcurrentSlideIndex(0);
        index = 0;
      }
      const name = slideData?.images[index]?.name;
      if (name) setcurrentAccrodian(name);
      carousel.current.next();
    }
    // onMove means if dragging or swiping in progress.
    return (
      <button
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
        onClick={() => rightClickEvent.next(null)}
      />
    );
  };

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType, itemWidth },
    } = rest;
    const leftClickEvent = new Subject();

		useEffect(() => {
			let leftClickSubs = new Subscription();
			leftClickSubs = leftClickEvent
				.pipe(debounceTime(1000))
				.subscribe(() => onLeftClick());

			return () => {
				leftClickSubs.unsubscribe();
			};
		}, [leftClickEvent]);

		if (initSlideIndex === 0) setinitSlideIndex(currentSlide);

    function onLeftClick() {
      let index;
      if (currentSlideIndex === 0) {
        setcurrentSlideIndex(slideData.images.length - 1);
        index = slideData.images.length - 1;
      } else if (currentSlideIndex > 0) {
        setcurrentSlideIndex((prevIndex) => prevIndex - 1);
        index = currentSlideIndex - 1;
      }

			const name = slideData?.images[index]?.name;
			if (name) setcurrentAccrodian(name);
			onClick();
		}
		// onMove means if dragging or swiping in progress.
		return (
			<button
				className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
				onClick={() => leftClickEvent.next(null)}
			/>
		);
	};

  function onslideChange(index, cIdx) {
    let name = "";
    let i = 0;
    if (index > slideData?.images.length) i = index - slideData.images.length;
    else i = index;
    if (i === slideData.images.length) i = 0;
    name = slideData?.images[i]?.name;
    if (name) setcurrentAccrodian(name);
  }

	function closeAllModal() {
		closeModal();
		closeViewModal();
	}

	function setModalImage(data) {
		setselectedSlide(data);
		openViewModal();
	}

	function onViewDetailsClick() {
		if (modalMoreIsOpen) closeAllModal();
		else closeViewModal();
		setTimeout(() => {
			window.location.href = `#${selectedSlide?.name.toUpperCase()}`;
		}, 500);
	}

	// modal
	function openModal(type) {
		setModalMoreIsOpen(true);
	}

	function setSlideImages(name) {
		let index = 0;

    for (let i = 0; i < slideData.images.length; i++) {
      const e = slideData.images[i];
      if (e.name === name) {
        index = i;
        break;
      }
    }
    setcurrentSlideIndex(index);

		carousel.current.goToSlide(initSlideIndex + index, true);
		setcurrentAccrodian(name);
	}

	function closeModal(name) {
		setselectedSlide("");
		setModalMoreIsOpen(false);
		if (name) {
			setTimeout(() => {
				window.location.href = `#${name}`;
			}, 500);
		}
	}

	function openViewModal(type) {
		setModalViewImgIsOpen(true);
	}

	function closeViewModal() {
		setselectedSlide("");
		setModalViewImgIsOpen(false);
	}

	function afterOpenModal() {
		// references are now sync'd and can be accessed.
		// subtitle.style.color = '#f00';
	}

	const modal_view_button = [
		"btn-view",
		isGaragePlug ? "view_btn_garage" : "view_btn_all",
	].join(" ");
	const modal_back_button = [
		"btn-view btn-back",
		isGaragePlug ? "view_btn_garage" : "view_btn_all",
	].join(" ");

  return (
    <div className="slider-container">
      <div className="slider-header">
        <div className="slider-title">
          <span>
            {isBike ? "Bike" : isTruck ? "Truck" : "Car"} photos from user.
          </span>
          <span className="grey-color">Take a look</span>
        </div>
        <div className="slider-filter">
          {slideData?.areaName?.length > 0 &&
            slideData?.areaName.map((e, index) => (
              <a
                onClick={() => setSlideImages(e)}
                href={`#${e.toString().toUpperCase()}`}
                className={`filter-tag ${e === currentAccrodian ? "active" : ""
                  }`}
                key={index}
              >
                {e.toString().toUpperCase()}
              </a>
            ))}
          {isQuestionAvail && (
            <a
              href={`#QUESTIONNAIRE`}
              onClick={() => setcurrentAccrodian("QUESTIONNAIRE")}
              className={`filter-tag ${currentAccrodian === "QUESTIONNAIRE" ? "active" : ""
                }`}
            >
              QUESTIONNAIRE
            </a>
          )}
        </div>
      </div>

			<div className="slider-photos">
				<Carousel
					ref={carousel}
					infinite={true}
					itemClass="image-item"
					customRightArrow={<CustomRightArrow />}
					customLeftArrow={<CustomLeftArrow />}
					responsive={responsive}
				>
					{Array.isArray(slideData?.images) &&
						slideData?.images.length &&
						slideData.images.map((e, index) => (
							<CarouselItem
								img={e.img}
								key={index}
								onItemClick={() => setModalImage(e)}
							/>
						))}
				</Carousel>
			</div>

			{slideData && (
				<div className="slider-view-all">
					<button onClick={openModal}>View all damages</button>
				</div>
			)}

      {/* View more button */}
      <Modal
        isOpen={modalMoreIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="Modal bottomsheet"
        overlayClassName="Overlay"
      >
        <div className="modal-dialog modal-xl m-xl-0 slider_modal_outer view_all_modal_outer">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="all_damage_modal_title modal-title mx-auto" id="staticBackdropLabel">
                ALL {isBike ? "BIKE" : isTruck ? "TRUCK" : "CAR"} DAMAGES FROM USER
              </h5>
              <button
                onClick={closeModal}
                type="button"
                className={modal_back_button}
                data-bs-dismiss="modal"
              >
                Back
              </button>
            </div>
            <div className="modal-body">
              {Array.isArray(modalData) &&
                modalData.map((e) => (
                  <div>
                    <h3 className="view_all_modal_img_head">
                      {(e?.label?.toString().toLowerCase().includes("odometer")
                        ? e?.label
                        : e?.label_name
                      ).toUpperCase() || ""}
                    </h3>
                    <div className="row">
                      {Array.isArray(e?.subpanel) &&
                        e?.subpanel.map((subitem) => (
                          <div
                            className="col-lg-3 col-md-4 col-sm-6"
                            style={{ marginBottom: "24px" }}
                          >
                            <div className="car-photo">
                              <img
                                src={subitem?.url}
                                alt="car"
                                style={{ minHeight: "135px" }}
                              />
                              <button
                                style={{
                                  height: "30px",
                                  width: "30px",
                                }}
                                onClick={() =>
                                  setModalImage({
                                    name: (e.label
                                      .toString()
                                      .toLowerCase()
                                      .includes("odometer")
                                      ? e?.label
                                      : e?.label_name
                                    ).toUpperCase(),
                                    img: subitem?.url,
                                  })
                                }
                                type="button"
                                className="btn-expand"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                              >
                                <img src="./img/expand.jpg" alt="" />
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="modal-footer footer-content px-0">
                      <div>
                        <button
                          type="button"
                          className={modal_view_button}
                          data-bs-dismiss="modal"
                          onClick={() =>
                            closeModal(
                              e?.label?.toString()
                                .toLowerCase()
                                .includes("odometer")
                                ? e?.label.toUpperCase()
                                : e?.label_name
                            )
                          }
                        >
                          VIEW{" "}
                          {e?.label?.toString().toLowerCase().includes("odometer")
                            ? e?.label
                            : (e?.label_name).toUpperCase()}
                        </button>
                        {e?.label?.toString()
                          .toLowerCase()
                          .includes("odometer") ? (
                          ""
                        ) : (
                          <span className="mr-auto">
                            Check damage details and repair cost for exterior
                          </span>
                        )}
                        {/* </span> */}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal>

			{/* Image View Modal */}
			<Modal
				isOpen={modalViewImgIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeViewModal}
				contentLabel="Example Modal"
				className="Modal"
				overlayClassName="Overlay"
			>
				<div className="modal-dialog modal-xl m-xl-0">
					<div className="modal-content p-xl-0" style={{minWidth:"70vw"}}>
						<div className="modal-header justify-content-center">
							<h5 className="modal-title" id="staticBackdropLabel">
								{selectedSlide?.name || ""}
							</h5>
							<button
								type="button"
								className="modal-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={closeViewModal}
							>
								<img src="./img/expand.jpg" alt="" />
							</button>
						</div>
						<div className="modal-body img-wrapper" onWheel={(e)=>{
              if (e.deltaY > 0) {
                setZoomLevel(Math.min(3, zoomLevel + 0.1)); // Limit maximum zoom to 3x
              } else if (e.deltaY < 0) {
                setZoomLevel(Math.max(1, zoomLevel - 0.1)); // Limit minimum zoom to 1x
              }
            }} style={{overflow:"hidden"}}
						onMouseMove={(e) => {
							// update cursor position
							const elem = e.currentTarget;
							const { top, left } = elem.getBoundingClientRect();

							// calculate cursor position on the image
							const x = e.pageX - left - window.pageXOffset;
							const y = e.pageY - top - window.pageYOffset;
							setXY([x, y]);
						}}>
							{!slideData && <Skeleton></Skeleton>}
							{slideData && selectedSlide && (
								<>
									<img
                    className="modal-img"
										src={selectedSlide?.img}
										
										onMouseEnter={(e) => {
											// update image size and turn-on magnifier
											const elem = e.currentTarget;
											const { width, height } = elem.getBoundingClientRect();
											setSize([width, height]);
											setShowMagnifier(true);
										}}
										onMouseMove={(e) => {
											// update cursor position
											const elem = e.currentTarget;
											const { top, left } = elem.getBoundingClientRect();

											// calculate cursor position on the image
											const imgx = e.pageX - left - window.pageXOffset;
											const imgy = e.pageY - top - window.pageYOffset;
											setimgXY([imgx, imgy]);
										}}
										onMouseLeave={() => {
											// close magnifier
											setShowMagnifier(false);
										}}
                    
										alt={"img"}
									/>

									<div
										style={{
											display: showMagnifier ? "" : "none",
											position: "absolute",

											// prevent maginier blocks the mousemove event of img
											pointerEvents: "none",
											// set size of magnifier
											height: `${magnifierHeight}px`,
											width: `${magnifieWidth}px`,
											// move element center to cursor pos
											top: `${y - magnifierHeight/2 }px`,
											left: `${x - magnifieWidth / 2}px`,
											opacity: "1", // reduce opacity so you can verify position
											border: "1px solid lightgray",
											backgroundColor: "white",
											backgroundImage: `url('${selectedSlide?.img}')`,
											backgroundRepeat: "no-repeat",
											//calculate zoomed image size
											backgroundSize: `${imgWidth * zoomLevel}px ${
												imgHeight * zoomLevel
											}px`,

											//calculete position of zoomed image.
											backgroundPositionX: `${
												-imgx * zoomLevel + magnifieWidth / 2
											}px`,
											backgroundPositionY: `${
												-imgy * zoomLevel + magnifierHeight / 2
											}px`,
										}}
                  
									></div>
								</>
							)}
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className={modal_view_button}
								data-bs-dismiss="modal"
								onClick={() => onViewDetailsClick()}
							>
								VIEW DETAILS
							</button>
							{selectedSlide?.name
								?.toString()
								.toLowerCase()
								.includes("odometer") ? (
								""
							) : (
								<span>Check damage details and repair cost for this part</span>
							)}
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default ReportSlide;
