import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const ReportV2Redirection = () => {
    let { id } = useParams();

    useEffect(() => {
        if (id) {
            const searchParams = new URLSearchParams(window.location.search);
            const param_jwt = searchParams.get('jwt');
            const showMinor = searchParams?.get('showMinor') ?? null;

            if (param_jwt) {
                let url = `${process.env.REACT_APP_EMAIL_REPORT_V2_URL}/email-report/${id}?jwt=${param_jwt}`;
                if (showMinor === 'true') {
                    url += '&showMinor=true';
                }

                // Redirect to the new URL
                window.location.replace(url);  // use replace() instead of href to avoid adding the URL to the browser history
            }
        }
    }, [id]); // Add id as a dependency

    return null; // return null instead of an empty fragment to indicate nothing is rendered
};

export default ReportV2Redirection;
