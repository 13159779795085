import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { EventSourcePolyfill } from "event-source-polyfill";
import Modal from "react-modal";
import axios from "axios";
import { Subscription, timer } from "rxjs";

import { useParams } from "react-router-dom";
import useIsMobile from "../../shared/hooks/useIsMobile";
// Components
import ReportAccordianList from "./ReportAccordianList";
import ReportBanner from "./ReportBanner";
import ReportFooter from "./ReportFooter";
import ReportHeader from "./ReportHeader";
import ReportSlide from "./ReportSlide";

// Helper fuctions
import {
  groupReport,
  getDamageType,
  checkClaimStatus,
  getSessionID,
} from "./report-helper";
import useInterval from "../../shared/hooks/useInterval";
import COMPANY_ID from "../../shared/store/app-constant";
import { useHistory } from 'react-router-dom';

const bikeCompany = [
  "8495f71a-78ea-11ec-b553-9be06d09751a",
  "de245c92-e319-11eb-878b-004238f3c84c",
];

const truckCompany = ["ce6e27c8-957c-11ec-afc4-8b377d5291db"];

let wholeData;
function Report() {

  let { id } = useParams();
  const history = useHistory();

  const [isMobile] = useIsMobile();
  const [isLabellerAvailable, setisLabellerAvailable] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [headerData, setheaderData] = useState("");
  const [bannerData, setbannerData] = useState("");
  const [slideData, setslideData] = useState("");
  const [imageGroups, setimageGroups] = useState([]);
  const [questions, setquestions] = useState([]);
  const [modalOpen, setmodalOpen] = useState(false);
  const [sessionId, setsessionId] = useState(getSessionID());
  const [fullData, setFullData] = useState(null);
  const [callSSE, setCallSSE] = useState(false);
  const [isBike, setIsBike] = useState(false);
  const [isTruck, setIsTruck] = useState(false);
  const [showLanguageButton, setShowLanguageButton] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [isGaragePlug, setGaragePlug] = useState(false);
  const [assessmentId, setAssessmentId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [tokenFormCommonError, setTokenFormCommonError] = useState("");
  const [jwt, setJwt] = useState("");

  /**
   * For orientation
   */
  useEffect(() => {
    if (isMobile) setmodalOpen(true);
    else setmodalOpen(false);
  }, [isMobile]);

  /**
   * Build Assesment data
   * @param {Object} data
   * @return null
   */
  useEffect(() => {
    if (fullData || wholeData) {
      const buildAssesmentData = (data) => {

        let odometerReading;
        if (data?.company_id !== COMPANY_ID.FLEXCLUB) {
          odometerReading = (data?.odometer_value) ? data?.odometer_value : "Not Available";
        }

        if (data?.answers) {
          if (Array.isArray(data.answers) && data.answers.length) {
            const buildAnswers = data.answers
              .filter(
                (e) =>
                  !e?.dashboard_label
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes("signature")
              )
              .map((e) => ({
                question: e?.question,
                answer: e?.answers[0],
                type: e?.type,
              }));

            setquestions(
              buildAnswers.filter(
                (e) =>
                  e.answer && !e.question.toLowerCase().includes("signature")
              )
            );
            if (data?.company_id == COMPANY_ID.FLEXCLUB) {
              const odometerNumber = buildAnswers.filter((e) =>
                e.question.toLowerCase().includes("odometer")
              );
              if (odometerNumber.length) {
                odometerReading = (odometerNumber[0]["answer"]) ? odometerNumber[0]["answer"] : "Not Available";
              } else {
                odometerReading = "Not Available";
              }
            }
          }
        }

        if (data?.company_id == COMPANY_ID.GARAGE_PLUG) {
          setShowLanguageButton(true);
          setShowDownloadButton(true);
          setGaragePlug(true);
        }

        if (data) {
          setAssessmentId(data.id)
        }

        const {
          assessment_location,
          field_two,
          created_at,
          id,
          make,
          model,
          licence_no,
          vin_no,
          registration_no,
          claim_areas_details,
          company_id,
          color,
          engine_no,
          logo_file_path,
        } = data;

        // Set Header data
        setheaderData({
          iDate: created_at,
          ilocation: assessment_location?.location_name,
          pLocation: data?.preferred_location?.location_name,
        });

        const car = `${make} ${model?.replace(/[^a-zA-Z ]/g, "")}`
          ?.slice(0, 20)
          ?.split(" ");

        // Set Banner data
        setbannerData({
          carName:
            car?.length > 1
              ? car?.slice(0, car?.length - 1)?.join(" ")
              : car[0],
          vin: vin_no,
          license: licence_no,
          reportId: id,
          regNo: registration_no,
          model: model,
          make: make,
          pNo: field_two,
          img: logo_file_path,
          engine_no,
          color,
          odometer: odometerReading,
          companyId: company_id,
        });

        const imageGroups = groupReport(
          data.assessment_file_image_details,
          isLabellerAvailable
        );

        let imageGroupData = [];
        if (Array.isArray(imageGroups)) {
          imageGroups.forEach((item) => {
            item.annotateImg = item.url;
            item.showModal = false;

            // subpannel
            if (item.subpanel && Array.isArray(item.subpanel)) {
              item.subpanel.forEach(async (element) => {
                element["damage"] = getDamageType(
                  element.tag,
                  isLabellerAvailable
                );
                element.annotateImg = element.url;

                // claimed tag
                if (
                  Array.isArray(claim_areas_details) &&
                  claim_areas_details.length
                ) {
                  element["isClaimed"] = checkClaimStatus(
                    claim_areas_details,
                    element.tag
                  );
                }
              });
            }

            imageGroupData.push({ ...item });
          });
        }

        setimageGroups(imageGroups);

        if (Array.isArray(imageGroups) && imageGroups.length) {
          const areaName = imageGroups.map((e) =>
            e?.label?.toString().toLowerCase().includes("odometer")
              ? e?.label
              : e?.label_name
          );
          const images = [];
          imageGroups.forEach((e) => {
            if (Array.isArray(e?.subpanel) && e?.subpanel.length) {
              e.subpanel.forEach((t) => {
                images.push({
                  name: e?.label?.toString().toLowerCase().includes("odometer")
                    ? e?.label
                    : e?.label_name,
                  img: t?.url,
                });
              });
            }
          });

          setslideData({ areaName, images });
        }

        if (Array.isArray(imageGroups) && imageGroups.length) {
          imageGroups.forEach((e) => {
            if (Array.isArray(e?.subpanel) && e?.subpanel.length) {
              e.subpanel.forEach((t) => {
                const isLabeller = t.tag.some((x) => x.source === "LABELLER");
                if (isLabeller) {
                  setisLabellerAvailable(true);
                }
              });
            }
          });
        }
        if (truckCompany.includes(fullData.company_id)) {
          setIsTruck(true);
        }

        if (bikeCompany.includes(bannerData.companyId)) {
          setIsBike(true);
        }
      };

      buildAssesmentData(fullData);
    }
  }, [fullData, isLabellerAvailable]);

  /**
   * API Calls
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const param_jwt = searchParams.get('jwt');
    if (param_jwt) {
      setJwt(param_jwt);
    } else {
      setShowAlert(true);
      const err_message = "Authentication Error: Please check the URL!"
      setSnackbarMessage(err_message);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setSnackbarMessage("");
      }, 5000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [jwt, isLabellerAvailable]);

  useEffect(() => {
    if (jwt && id) {
      getAssesmentDetails(id);
    }
  }, [jwt])

  const getAssesmentDetails = (id) => {
    const searchParams = new URLSearchParams(window.location.search);
    const showMinor = searchParams?.get('showMinor') || null;
    let url = `${process.env.REACT_APP_SERVER_URL}coreservice/public/assessments/${id}/aireport?jwt=${jwt}`;
    if (showMinor)
      url += `&show-minor-damage=true`
    axios
      .get(
        url
      )
      .then((res) => {
        if (res?.data) {
          setFullData({ ...res.data });
        }
        setCallSSE(true);
        setIsDataFetched(true);
      })
      .catch((error) => {
        if (error?.response && error?.response?.status >= 400 && error?.response?.status < 500) {
          setIsOpen(true);
        } else {
          setShowAlert(true);
          let err_data = error?.response?.data || {};
          const err_message = err_data?.error_message || "Something went wrong, please contact Carscan Support!"
          setSnackbarMessage(err_message);
          const timeoutId = setTimeout(() => {
            setShowAlert(false);
            setSnackbarMessage("");
          }, 5000);
          return () => {
            clearTimeout(timeoutId);
          };
        }
      });
  };

  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    contact_no: '',
    vin_no: ''
  });
  const [errors, setErrors] = useState({
    contact_no: '',
    vin_no: ''
  });

  const handleCloseModal = () => {
    setFormData({
      contact_no: '',
      vin_no: ''
    });
    setErrors({
      contact_no: '',
      vin_no: ''
    });
    setIsOpen(false);
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let formIsValid = true;
    setTokenFormCommonError("");
    const newErrors = {};
    if (!formData.contact_no.trim() && !formData.vin_no.trim()) {
      setTokenFormCommonError("Minimun one field is required.");
      formIsValid = false;
    } else {
      if (formIsValid) {
        const contactRegex = /^\d+$/;
        if (formData.contact_no.trim() && (!contactRegex.test(formData.contact_no.trim()))) {
          newErrors.contact_no = 'Contact no. is not valid.';
          setTokenFormCommonError("");
          formIsValid = false;
        } else if (contactRegex.test(formData.contact_no.trim())) {
          newErrors.contact_no = '';
          formIsValid = true;
        }
      }
      if (formIsValid) {
        const vinRegex = /^[a-zA-Z0-9]{17}$/;
        if (formData.vin_no.trim() && (!vinRegex.test(formData.vin_no.trim()))) {
          newErrors.vin_no = 'Vin no. is not valid.';
          setTokenFormCommonError("");
          formIsValid = false;
        } else if (vinRegex.test(formData.vin_no.trim())) {
          newErrors.vin_no = '';
          formIsValid = true;
        }
      }
    }
    if (!formIsValid) {
      setErrors(newErrors);
    } else {
      let url = `${process.env.REACT_APP_SERVER_URL}coreservice/public/assessments/${id}/refresh-jwt?jwt=${jwt}`;
      if (formData?.contact_no?.trim()) {
        url += `&phoneNumber=${formData?.contact_no?.trim()}`
      }
      if (formData?.vin_no?.trim()) {
        url += `&vinNo=${formData?.vin_no?.trim()}`
      }
      axios
        .get(
          url
        )
        .then((res) => {
          handleCloseModal();
          const newJwt = res?.data;
          const pathname = window.location.pathname;
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set('jwt', newJwt);
          const newUrl = `${pathname}?${searchParams.toString()}`;
          history.replace(newUrl);
          setJwt(newJwt);
        })
        .catch((err) => {
          handleCloseModal();
          setShowAlert(true);
          let err_data = err?.response?.data || {};
          let err_message = "";
          if (err_data?.error_message) {
            err_message = `Error: ${err_data?.error_message}`
          } else {
            err_message = "Something went wrong, please contact Carscan Support!";
          }
          setSnackbarMessage(err_message);
          const timeoutId = setTimeout(() => {
            setShowAlert(false);
            setSnackbarMessage("");
          }, 5000);
          return () => {
            clearTimeout(timeoutId);
          };
        });
    }
  };

  return (
    <Fragment>
      <section className={isGaragePlug ? 'garage_plug_report_outer' : ''}>
        <div className="pagebreak bg-black h-100 p-t-0">
          {(showAlert && snackbarMessage) && (
            <div className="custom_snackbar">
              <p className="m-0 text-center">{snackbarMessage}</p>
            </div>
          )}
          <Modal
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            contentLabel="Example Modal"
            className="Modal"
            overlayClassName="Overlay"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
          >
            <div className="modal-dialog modal-xl m-xl-0 accordian_modal">
              <div className="modal-content px-4 py-3" style={{ minWidth: "30rem" }}>
                <div>
                  <h4 className="text-center pt-2 py-4">Please fill any one field for generating the AI Report</h4>
                </div>
                <form>
                  <div className="form-group mb-3">
                    <label htmlFor="contact_no">Contact No:</label>
                    <input
                      type="text"
                      className={`form-control ${errors.contact_no ? 'is-invalid' : ''}`}
                      id="contact_no"
                      name="contact_no"
                      value={formData.contact_no}
                      onChange={handleInputChange}
                    />
                    {errors.contact_no && <div className="invalid-feedback">{errors.contact_no}</div>}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="vin_no">Vin No:</label>
                    <input
                      type="text"
                      className={`form-control ${errors.vin_no ? 'is-invalid' : ''}`}
                      id="vin_no"
                      name="vin_no"
                      value={formData.vin_no}
                      onChange={handleInputChange}
                    />
                    {errors.vin_no && <div className="invalid-feedback">{errors.vin_no}</div>}
                  </div>
                  <div className="mb-3">
                    <p className="m-0 text-center text-danger">
                      {tokenFormCommonError}
                    </p>
                  </div>
                  <button className="d-block mt-5 mb-3 px-4 mx-auto btn btn-success" onClick={handleSubmit}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </Modal>

          <ReportHeader
            headerData={headerData}
            isLabellerAvailable={isLabellerAvailable}
            isDataFetched={isDataFetched}
            showLanguageButton={showLanguageButton}
            isGaragePlug={isGaragePlug}
          />
          <ReportBanner
            bannerData={bannerData}
            isBike={isBike} isTruck={isTruck}
            isDataFetched={isDataFetched}
            showDownloadButton={showDownloadButton}
            isGaragePlug={isGaragePlug}
            questions={questions}
            assessmentId={assessmentId}>
            <ReportSlide
              slideData={slideData}
              modalData={imageGroups}
              isQuestionAvail={!!questions.length}
              isBike={isBike}
              isTruck={isTruck}
              isGaragePlug={isGaragePlug}
            />
          </ReportBanner>
        </div>
        <section className="p-20 bg-black accordian-container">
          <ReportAccordianList
            data={imageGroups}
            isLabellerAvailable={isLabellerAvailable}
            questions={questions}
          />
          <ReportFooter />
        </section>
      </section>
    </Fragment>
  );
}

export default Report;
