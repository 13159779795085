import React, { useEffect } from "react";
import { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { fetchCountryCodeFrom } from "./CountryCode";
import "./googlePlacesAutoComplete.css";
import { useParams } from "react-router-dom";

/**
 * Google places auto complete component to render drop down based on search
 * @param {*} param0
 * @returns
 */
const GooglePlacesAutoComplete = ({ getFeoCodedLocation, preferredLocation, setPreferredLocation }) => {
  // State variable declaration
  const [address, setAddress] = useState("");
  const [countryCode, setCountyCode] = useState("");
  const { Country } = useParams();


  /**
   * Function to append loading div based on a condition
   * @param {*} isLoading - This parameter will show hide the loading div
   * @returns
   */
  const canShowLoadingDiv = (isLoading) => {
    if (isLoading) {
      return <div>Loading...</div>;
    }
  };
  /**
   * Generates the places drop down and adds styling based active/inactive selection and index of the dropdown
   * @param {*} suggestionDropDown
   * @param {*} getSuggestionItemProps
   */
  const generateSuggestionDropDown = (
    suggestionDropDown,
    getSuggestionItemProps,
    loading
  ) => {
    return (
      <div className="autocomplete-dropdown-container">
        {canShowLoadingDiv(loading)}

        <div className="autocomplete_dropdown_container_inner">
          {suggestionDropDown
            .filter((data, i) => {
              return i < 6;
            })
            .map((suggestion, i) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              return (
                <div
                  key={i}
                  {...getSuggestionItemProps(suggestion, {
                    className
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
        </div>

      </div>
    );
  };
  /**
   * Takes input and fetches the geo location json object
   * @param {*} keyBoardInput - Input from search bar or any input tag
   * @returns
   */
  const fetchGeoLocationDetailsBasedOnInput = async (searchBarInput) => {
    const result = await geocodeByAddress(searchBarInput);
    const latLang = await getLatLng(result[0]);
    setAddress(searchBarInput);
    return {
      lat: latLang.lat,
      long: latLang.lng,
      location: result[0].formatted_address,
    };
  };

  /**
   * On change search bar handler for auto complete component
   * @param {*} searchBarInput
   */
  const onChangeSearchBarHandler = async (searchBarInput) => {
    setAddress(searchBarInput);
    const geoLocationData = await fetchGeoLocationDetailsBasedOnInput(searchBarInput);
    getFeoCodedLocation(geoLocationData);
  };

  useEffect(() => {
    let country = Country.replace("-", " ");
    const countryCode = fetchCountryCodeFrom(country);
    setCountyCode(countryCode);
  }, []);

  useEffect(() => {
    if (!address?.length) {
      setPreferredLocation(null);
    }
  }, [address]);


  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={onChangeSearchBarHandler}
      searchOptions={{ componentRestrictions: { country: [countryCode] } }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: "Type location here",
              className: "location-search-input",
            })}
          />
          {generateSuggestionDropDown(
            suggestions,
            getSuggestionItemProps,
            loading
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlacesAutoComplete;