import React, { Component, useState } from 'react';
import "./chooseLocation.css";
import GooglePlacesAutoComplete from '../../components/google-location-auto-complete/GooglePlacesAutoComplete';
import axios, { Axios } from "axios";
import { useParams } from "react-router-dom";
import swal from 'sweetalert';


function ChooseLocation() {
  const [preferredLocation, setPreferredLocation] = useState(null);
  const { Id } = useParams();
  

  const getFeoCodedLocation = async (data) => {
    setPreferredLocation(data);
  };

  const confirmLocation = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}coreservice/public/journey-id/${Id}/submit-preferred-location`,
        preferredLocation
      )
      .then((response) => {
        if (response) {
        swal("Thank you!", "Your Form is submitted", "success");
        }
      })
      .catch((err) => {
        swal("Sorry!","Oops, something went wrong.Please try again later","error");
      });
  }

  return (
    <>
      <section className='main_choose_location_outer'>
        <div className='main_choose_location_inner'>
          <div className='choose_location_inner'>
            <div className="googlePlace_container">
              <GooglePlacesAutoComplete
                getFeoCodedLocation={getFeoCodedLocation}
                preferredLocation={preferredLocation}
                setPreferredLocation={setPreferredLocation}
              />
              <p className="label">
                Type and choose location
              </p>
            </div>
            <button className='btn w-100 confirm_button' disabled={!preferredLocation} onClick={confirmLocation}>Confirm
            </button>
          </div>
        </div>
      </section>
    </>
  )
}

export default ChooseLocation;