// here have all country name which we are using and its country-name-code too 
const countryNamesAndCode = [
  { name: "LONDON", code: "GB" },
  { name: "SOUTH AFRICA", code: "ZA" },
  { name: "INDIA", code: "IN" },
  { name: "NIGERIA", code: "NG" },
  { name: "KENYA", code: "KE" },
  { name: "SAUDI ARABIA", code: "SA" },
  { name: "QATAR", code: "QA" },
  { name: "UAE", code: "AE" },
  { name: "AUSTRALIA", code: "AU" },
  { name: "UGANDA", code: "UG" },
  { name: "OMAN", code: "OM" },
  { name: "CAMEROON", code: "CM" },
  { name: "SENEGAL", code: "SN" },
  { name: "GHANA", code: "GH" },
  { name: "TANZANIA", code: "TZ" },
  { name: "NAMIBIA", code: "NA" },
  { name: "BOTSWANA", code: "BW" },
  { name: "KUWAIT", code: "KW" },
  { name: "ZAMBIA", code: "ZM" },
  { name: "USA", code: "US" },
  { name: "EGYPT", code: "EG" },
  { name: "IVORY COAST", code: "CI" },
];

export const fetchCountryCodeFrom = (name) => {
  const countryCode = countryNamesAndCode.find((country) => {
    return name.toLowerCase() === country.name.toLowerCase();
  });
  return countryCode.code;
}