import "./App.css";
import Report from "./pages/report/Report";
import { Route, Switch, Link } from "react-router-dom";
import WhatsAppJourney from "./pages/WhatsAppJourneyForm/WhatsAppJourney";
import ChooseLocation from "./pages/choose-location/ChooseLocation";
import ReportV2Redirection from "./pages/report-v2-redirection/ReportV2Redirection";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/:id">
          {/* <Report /> */}
          <ReportV2Redirection />
        </Route>
      </Switch>
      {/* <Route exact path="/whatsApp/Journey/:Id" component={WhatsAppJourney} /> */}
      <Route exact path="/choose-location/:Id/:Country" component={ChooseLocation} />
    </div>
  );
}

export default App;