import React, { useState } from 'react';

const initTheme = {
    header: '#2C2C2C',
    gray: '#969696',
    white: '#fff',
    light: '#F5F5F7',
    tamato: '#F74646',
    black: '#000000',
    pink: '#D6129A',
    blue: '#126CD6',
    pinkOpacity: '#D6129A00',
    blueOpacity: '#126CD600',
}

const ThemeContext = React.createContext(initTheme);


export const ThemeContextProvidor = (props) => {
    const [theme, setTheme] = useState(initTheme);

    return (
        <ThemeContext.Provider value={{theme, setTheme: setTheme}}>
            {props.children}
        </ThemeContext.Provider>
    )
} 

export default ThemeContext;
