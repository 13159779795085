import React from "react";
import { useState ,useRef} from "react";
import "./whatsAppFlow.css";
import image1 from "../../Assets/1024px-KIA_motors 1.svg";
import image2 from "../../Assets/Carscan_white.svg";
import axios, { Axios } from "axios";
import { useParams } from "react-router-dom";
import swal from 'sweetalert';




function WhatsAppJourney() {
  const { Id } = useParams();
  const [user, setUser] = useState({});
  const formRef = useRef(null);


  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dateOfBreakage, setDateOfBreakage] = useState(new Date());
  const [dateIssued, setDateIssued] = useState(new Date());
  const [declarationDate, setDeclarationDate] = useState(new Date());
  const [premisesOccupied, setPremisesOccupied] = useState("No");
  const [windScreen, setWindScreen] = useState();
  const [glass, setGlass] = useState();
  const [signWritingOnBrokenGlass, setSignWritingOnBrokenGlass] = useState();
  const [declaration, setDeclaration] = useState(false);

  let name, value;

  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;

    setUser({ ...user, [name]: value });
  };

  function handleSubmit(event) {
    event.preventDefault();
    user.dateOfBreakage = dateOfBreakage.toDateString();
    user.dateIssued = dateIssued.toDateString();
    user.declarationDate = declarationDate.toDateString();
    user.premisesOccupied = premisesOccupied;
    user.windScreen = windScreen;
    user.glass = glass;
    user.signWritingOnBrokenGlass = signWritingOnBrokenGlass;
    user.declaration = declaration;

    const userData = {
      journey_id: Id,
      sections: [
        {
          name: "Details of Broken Glass",
          data: [
            { answer: user.glass, question: "Glass" },
            {
              answer: user.glassDetails,
              question: "Full Description of Broken Glass",
            },
            {
              answer: user.valueOfInsuredGlass,
              question: "Total value of insured glass",
            },
            {
              answer: user.lastValued,
              question: "Total value when last valued",
            },
            {
              answer: user.signWritingOnBrokenGlass,
              question: "Any signwriting on broken glass",
            },
            { answer: user.SizeInMillimeters, question: "Size in millimeters" },
          ],
        },

        {
          name: "Declaration of Insured person",
          data: [
            { answer: "100", question: "Capacity" },
            { answer: "10-02-2023", question: "Date" },
            {
              answer: user.declaration,
              question:
                "I/We declare that to the best of my/our knowledge the above statements are truly made.",
            },
          ],
        },
        {
          name: "Details of insured",
          data: [
            { answer: user.address, question: "Address" },
            { answer: user.agentName, question: "Broker/AgentName" },
            { answer: user.emailId, question: "Email Id" },
            { answer: user.firstName, question: "FirstName" },
            { answer: user.occupation, question: "Occupation" },
            { answer: user.telephoneNumber, question: "Telephone Number" },
          ],
        },

        {
          name: "Date of Occurance",
          data: [
            { answer: user.dateOfOccurance, question: "Date of Occurance" },
            { answer: user.causeOfBreakage, question: "Cause of Breakage" },
            {
              answer: user.personReponsibilityForBreakage,
              question: "Person Responsible for Breakage",
            },
            { answer: user.timeOfBreakage, question: "Time of Breakage" },
          ],
        },

        {
          name: "Details of Premises",
          data: [
            {
              answer: user.AddressesOfPremisesWhereBreakageOccurred,
              question: "Address of premises where Breakage occurred",
            },
            {
              answer: user.premisesOccupied,
              question: "Were premises occupied",
            },
            { answer: user.byWhom, question: "By Whom" },
            {
              answer: user.purposeOfWhichOccupied,
              question: "Purpose of which occupied",
            },
          ],
        },

        {
          name: "Details of Vehicle",
          data: [
            { answer: user.DateIssued, question: "Date Issued" },
            {
              answer: user.driverName,
              question: "Driver's Name",
            },
            { answer: user.licenseNumber, question: "License Number" },
            { answer: user.placeIssued, question: "Place Issued" },
            { answer: user.windScreen, question: "Windscreen" },
          ],
        },
      ],
    };
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}coreservice/public/submit-whatsapp-form`,
        userData
      )
      .then((response) => {
        console.log(response);
        if (response) {
        swal("Thank you!", "Your Form is submitted", "success");
        setIsSubmitted(true);
        }
      })
      .catch((err) => {
        console.log(err);
        swal("Sorry!","Oops, something went wrong.Please try again later","error");
      });

        formRef.current.reset();

      
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit} key={isSubmitted ? 'submitted' : 'not-submitted'} >
      <div className="main_body_outer">
        <header>
          <div className="custom_container">
            <div className="header_inner">
              <div className="left_part">
                <div className="company_log">
                  <img src={image1} alt="" />
                </div>
                <div className="test_part">
                  <div className="head_part">
                    <p className="m-0">Insurance Part</p>
                  </div>
                  <div className="sub_part">
                    <p className="m-0">
                      Please enter below details fo fill a form.
                    </p>
                  </div>
                </div>
              </div>
              <div className="right_part">
                <button className="submit_button">
                  <p className="m-0">Submit</p>
                </button>
              </div>
            </div>
          </div>
        </header>
        <section className="body_outer">
          <div className="custom_container">
            <div className="main_body_inner">
              <div className="tab">
                <input type="checkbox" className="tab_label_input" id="chck1" />
                <label className="tab-label" htmlFor="chck1">
                  Details of insured
                </label>
                <div className="tab-content">
                  <div className="inputs_outer">
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Broker/Agent Name"
                        name="agentName"
                        value={isSubmitted ? '' :user.agentName}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        value={isSubmitted ? '' : user.firstName}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs full_inputs">
                      <input
                        type="text"
                        placeholder="Address"
                        name="address"
                        value={isSubmitted ? '' :user.address}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Occupation"
                        name="occupation"
                        value={isSubmitted ? '' :user.occupation}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Telephone Number"
                        name="telephoneNumber"
                        value={isSubmitted ? '' :user.telephoneNumber}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Email Id"
                        name="emailId"
                        value={isSubmitted ? '' :user.emailId}
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab">
                <input
                  type="checkbox"
                  className="tab_label_input"
                  id="chck2"
                  name="dateOfOccurance"
                />
                <label className="tab-label" for="chck2">
                  Date of Occurance
                </label>
                <div className="tab-content">
                  <div className="inputs_outer">
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Date of occurance"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        name="dateOfOccurance"
                        value={isSubmitted ? '' :user.dateOfOccurance}
                        onChange={handleInputs}
                      ></input>
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Time of Breakage"
                        name="timeOfBreakage"
                        onFocus={(e) => (e.target.type = "time")}
                        onBlur={(e) => (e.target.type = "text")}
                        value={isSubmitted ? '' :user.timeOfBreakage}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs full_inputs">
                      <input
                        type="text"
                        placeholder="Cause of Breakage"
                        name="causeOfBreakage"
                        value={isSubmitted ? '' :user.causeOfBreakage}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Person Responsibility for Breakage"
                        name="personReponsibilityForBreakage"
                        value={isSubmitted ? '' :user.personReponsibilityForBreakage}
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab">
                <input type="checkbox" className="tab_label_input" id="chck3" />
                <label className="tab-label" for="chck3">
                  Witnesses
                </label>
                <div className="tab-content">
                  <div className="inputs_outer">
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Name of Witness"
                        name="nameOfWitness"
                        value={isSubmitted ? '' :user.nameOfWitness}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs full_inputs">
                      <input
                        type="text"
                        placeholder="Address of Witness"
                        name="addressOfWitness"
                        value={isSubmitted ? '' :user.addressOfWitness}
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab">
                <input type="checkbox" className="tab_label_input" id="chck4" />
                <label className="tab-label" for="chck4">
                  Details of Premises
                </label>
                <div className="tab-content">
                  <div className="inputs_outer">
                    <div className="text_inputs full_inputs">
                      <input
                        type="text"
                        placeholder="Addresses of premises where Breakage occurred"
                        name="AddressesOfPremisesWhereBreakageOccurred"
                        value={isSubmitted ? '' :user.AddressesOfPremisesWhereBreakageOccurred}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="radio_input_outer full_inputs">
                      <div className="qs_label">
                        <p className="m-0">Were Premises occupied</p>
                      </div>
                      <div className="radio_inputs_inner">
                        <div className="radio_inputs">
                          <input
                            type="radio"
                            id="yes"
                            name="premisesOccupied"
                            value="yes"
                            onChange={(e) => {
                              setPremisesOccupied(e.target.value);
                            }}
                          />
                          <label for="yes">Yes</label>
                        </div>
                        <div className="radio_inputs">
                          <input
                            type="radio"
                            id="no"
                            name="premisesOccupied"
                            value="no"
                            onChange={(e) => {
                              setPremisesOccupied(e.target.value);
                            }}
                          />
                          <label for="no">No</label>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="By Whom?"
                        name="byWhom"
                        value={isSubmitted ? '' :user.byWhom}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs full_inputs">
                      <input
                        type="text"
                        placeholder="Purpose of which occupied"
                        name="purposeOfWhichOccupied"
                        value={isSubmitted ? '' :user.purposeOfWhichOccupied}
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab">
                <input type="checkbox" className="tab_label_input" id="chck5" />
                <label className="tab-label" for="chck5">
                  Details of Vehicle
                </label>
                <div className="tab-content">
                  <div className="inputs_outer">
                    <div className="radio_input_outer full_inputs">
                      <div className="qs_label">
                        <p className="m-0">Windscreen:</p>
                      </div>
                      <div className="radio_inputs_inner">
                        <div className="radio_inputs">
                          <input
                            type="radio"
                            id="tinted"
                            name="windScreen"
                            value="tinted"
                            onChange={(e) => {
                              setWindScreen(e.target.value);
                            }}
                          />
                          <label for="tinted">Tinted</label>
                        </div>
                        <div className="radio_inputs">
                          <input
                            type="radio"
                            id="clear"
                            name="windScreen"
                            value="clear"
                            onChange={(e) => {
                              setWindScreen(e.target.value);
                            }}
                          />
                          <label for="clear">clear</label>
                          <br />
                        </div>
                        <div className="radio_inputs">
                          <input
                            type="radio"
                            id="shatter_proof"
                            name="windScreen"
                            value="shatter_proof"
                            onChange={(e) => {
                              setWindScreen(e.target.value);
                            }}
                          />
                          <label for="shatter_proof">shatter proof</label>
                          <br />
                        </div>
                        <div className="radio_inputs">
                          <input
                            type="radio"
                            id="armer_plated"
                            name="windScreen"
                            value="armer_plated"
                            onChange={(e) => {
                              setWindScreen(e.target.value);
                            }}
                          />
                          <label for="armer_plated">armer plated</label>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Driver's Name"
                        name="driverName"
                        value={isSubmitted ? '' :user.driverName}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="License number"
                        name="licenseNumber"
                        value={isSubmitted ? '' :user.licenseNumber}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Place Issued"
                        name="placeIssued"
                        value={isSubmitted ? '' :user.placeIssued}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Date issued"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        name="DateIssued"
                        value={isSubmitted ? '' :user.DateIssued}
                        onChange={handleInputs}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab">
                <input type="checkbox" className="tab_label_input" id="chck6" />
                <label className="tab-label" for="chck6">
                  Details of Broker glass
                </label>
                <div className="tab-content">
                  <div className="inputs_outer">
                    <div className="text_inputs full_inputs">
                      <input
                        type="text"
                        placeholder="Full Description of Broker glass"
                        name="glassDetails"
                        value={isSubmitted ? '' :user.glassDetails}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Size in millimeters"
                        name="SizeInMillimeters"
                        value={isSubmitted ? '' :user.SizeInMillimeters}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="radio_input_outer full_inputs">
                      <div className="qs_label">
                        <p className="m-0">Glass:</p>
                      </div>
                      <div className="radio_inputs_inner">
                        <div className="radio_inputs">
                          <input
                            type="radio"
                            id="cracked"
                            name="glass"
                            value="cracked"
                            onChange={(e) => {
                              setGlass(e.target.value);
                            }}
                          />
                          <label for="cracked">cracked</label>
                        </div>
                        <div className="radio_inputs">
                          <input
                            type="radio"
                            id="shattered"
                            name="glass"
                            value="shattered"
                            onChange={(e) => {
                              setGlass(e.target.value);
                            }}
                          />
                          <label for="shattered">shattered</label>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="radio_input_outer full_inputs">
                      <div className="qs_label">
                        <p className="m-0">Any signwriting on broker glass</p>
                      </div>
                      <div className="radio_inputs_inner">
                        <div className="radio_inputs">
                          <input
                            type="radio"
                            id="yes2"
                            name="signWritingOnBrokenGlass"
                            value="yes"
                            onChange={(e) => {
                              setSignWritingOnBrokenGlass(e.target.value);
                            }}
                          />
                          <label for="yes2">Yes</label>
                        </div>
                        <div className="radio_inputs">
                          <input
                            type="radio"
                            id="no2"
                            name="signWritingOnBrokenGlass"
                            value="no"
                            onChange={(e) => {
                              setSignWritingOnBrokenGlass(e.target.value);
                            }}
                          />
                          <label for="no2">No</label>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Total value of insured glass"
                        name="valueOfInsuredGlass"
                        value={isSubmitted ? '' :user.valueOfInsuredGlass}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Total value when last valued?"
                        name="lastValued"
                        value={isSubmitted ? '' :user.lastValued}
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab">
                <input type="checkbox" className="tab_label_input" id="chck7" />
                <label className="tab-label" for="chck7">
                  Declaration of Insured person
                </label>
                <div className="tab-content">
                  <div className="inputs_outer">
                    <div className="radio_inputs">
                      <input
                        type="checkbox"
                        name="declaration"
                        id="declaration"
                        onChange={(e) => {
                          setDeclaration(true);
                        }}
                      />
                      <label for="declaration" id="declaration-label">
                        I/We declare that to the best of my/our knowledge the
                        above statements are truly made.
                      </label>
                      <br />
                      <br />
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Capacity"
                        name="capacity"
                        value={isSubmitted ? '' :user.capacity}
                        onChange={handleInputs}
                      />
                    </div>
                    <div className="text_inputs half_inputs">
                      <input
                        type="text"
                        placeholder="Date"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        name="Date"
                        value={isSubmitted ? '' :user.Date}
                        onChange={handleInputs}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className="custom_container">
            <img src={image2} alt="" className="logo" />
            <p className="m-0">Powered by - www.carscan.ai</p>
          </div>
        </footer>
      </div>
    </form>
  );
}

export default WhatsAppJourney;
